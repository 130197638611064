import axios from "axios";
import { call, put } from "redux-saga/effects";
import { TOOKAN_URL, TOOKAN_TOKEN } from "services/constants";
import { Creators } from "store/ducks/tracking";

const api = axios.create({
  baseURL: TOOKAN_URL,
  timeout: 300000,
});

export function* getTracking({ orderId }) {
  try {
    const data = {
      api_key: TOOKAN_TOKEN,
      order_ids: [orderId],
      include_task_history: 1
    };
    const response = yield call(
      api.post,
      "/v2/get_job_details_by_order_id",
      data
    );

    const DATA = response.data.data;

    let JOB_STATUS = [];
    const tasks = DATA.reduce((accumulator, currentValue) => {
        var task_history = currentValue.task_history;
        
        JOB_STATUS.push({job_status: currentValue.job_status, job_type: currentValue.job_type});
        
        let values = accumulator.concat(task_history);
        // console.log(values);
        
        return values;
    }, []);

    const _data = response.data.data[response.data.data.length -1];

    const isCanceled = JOB_STATUS[JOB_STATUS.length -1]?.job_status === 9;
    // const isDelivery = JOB_STATUS[JOB_STATUS.length -1]?.job_type === 1;

    const {
      job_pickup_name,
      job_pickup_phone,
      job_delivery_datetime,
      job_pickup_latitude,
      job_pickup_longitude,
      total_distance_travelled,
      job_pickup_address,
      started_datetime,
      completed_datetime,
      job_status,
      is_active,
      job_type,
      custom_field,
      tracking_link
    } = _data;

    const res = {
      job_pickup_name,
      job_pickup_phone,
      job_delivery_datetime,
      job_pickup_latitude,
      job_pickup_longitude,
      total_distance_travelled,
      job_pickup_address,
      started_datetime,
      completed_datetime,
      job_status,
      is_active,
      job_type,
      custom_field,
      task_history: tasks,
      tracking_link,
      isCanceled,
      // isDelivery
    }

    if (response.status === 200) {
      yield put(Creators.fetchTrackingSuccess(res));
    } else {
      yield put(Creators.fetchTrackingFailure(response.body));
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchTrackingFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      })
    );
  }
}
